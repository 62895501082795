import React from "react";

const TabArrow = ({ text, stats, openTab, setOpenTab }) => {
    return (
        <nav
            onClick={() => setOpenTab(text)}
            className={`cursor-pointer ${openTab === text ? "bg-roxo_oficial text-white" : "bg-white text-C6"}`}
        >
            <span>{text}</span>
            <aside>
                <strong>{stats.number}</strong>
                <ul>
                    {stats?.data.map((item, index) => {
                        return (
                            <li>
                                <span>{item.name}:</span>
                                <strong>{item.qtd}</strong>
                            </li>
                        );
                    })}
                </ul>
            </aside>
        </nav>
    );
};

export default TabArrow;
